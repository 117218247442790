import React from "react";
import { useEffect, useState } from "react";
import "../sticky/sticky.css";
import videoTicTacToe from "../../assets/iphoneMu.mp4";

function Sticky() {
  const [stickySections, setStickySections] = useState([""]);

  useEffect(() => {
    setStickySections([...document.querySelectorAll(".sticky")]);
    console.log("useEffect triggered");
  }, []);

  window.addEventListener("scroll", (e) => {
    transform(stickySections[0]);
    console.log("scroll");
  });

  function transform(section) {
    if (section) {
      const offsetTop = section.parentElement.offsetTop;
      const scrollSection = section.querySelector(".scrollSection");
      let percentage =
        ((window.scrollY - offsetTop) / window.innerHeight) * 100;
      percentage = percentage < 0 ? 0 : percentage > 400 ? 400 : percentage;
      scrollSection.style.transform = `translate3d(${-percentage}vw, 0, 0)`;
    }
  }

  return (
    <div className="stickyParent" id="projectsPage">
      <div className="sticky">
        <div className="scrollSection">


{/* This is the --- TIC TAC TOE --- project 
  _____________________________________________________________ */}
          <div className="projectSticky">
            <a href="https://tictactoeiparrea.netlify.app/" target="_blank">
              <div className="project">
                <div className="projectInfo">
                  <div className="projectTitle" id="projectOne">
                    <h1 className="projectTitleLineOne">My</h1>
                    <h1 className="projectTitleLineTwo">Work</h1>
                  </div>
                  <div className="projectDescription">
                    Step into the classic Tic Tac Toe game. Engage in matches
                    against a friend or the computer. Created with React and
                    boasting an intuitive interface. Enjoy seamless gameplay on
                    any device with real-time updates and smooth controls.
                    Elevate your gaming experience today.
                  </div>
                </div>
                <div className="projectPicture" id="projectOnePicture">
                  <video
                    src={videoTicTacToe}
                    autoPlay
                    loop
                    muted
                    playsInline
                    playing
                    alt=""
                  />
                </div>
              </div>
            </a>
            <a href="https://tictactoeiparrea.netlify.app/" target="_blank">
              <button className="projectButton">PLAY NOW →</button>
            </a>
          </div>


{/* This is the --- EVENT BUDDY --- project 
  _____________________________________________________________ */}
          <div className="projectSticky">
            <a
              href="https://main--eventb.netlify.app/"
              target="_blank"
            >
              <div className="project">
                <div className="projectInfo">
                  <div className="projectTitle">
                    <h1 className="projectTitleLineOne">{`Event`}</h1>
                    <h1 className="projectTitleLineTwo">{`Buddy`}</h1>
                  </div>
                  <div className="projectDescription">
                  {`Powered by `}    
                    <a
                      href="https://developer.ticketmaster.com/products-and-docs/apis/discovery-api/v2/"
                      target="_blank"
                      className="ticketMasterCredit"
                    >Ticketmaster Discovery API</a>, Event Buddy is a web application that allows you to search and save: sports, shows and music events. It is responsive across desktop and mobile platforms.
                  <br/>
                  Built with MongoDB, Express, React, Node.js {`(MERN)`}
                  <br/>
                  
                  
                  </div>
                </div>
                <div className="projectPicture" id="projectFivePicture"></div>
              </div>
            </a>

            <a
              href="https://main--eventb.netlify.app/"
              target="_blank"
            >
              <button className="projectButton">BUY TICKETS →</button>
            </a>
          </div>


{/* This is the --- CODE PAL --- project 
  _____________________________________________________________ */}

<div className="projectSticky">
            <a
              href="https://dev--jovial-macaron-61c636.netlify.app/"
              target="_blank"
            >
              <div className="project">
                <div className="projectInfo">
                  <div className="projectTitle">
                    <h1 className="projectTitleLineOne">{`< Code`}</h1>
                    <h1 className="projectTitleLineTwo">{`Pal >`}</h1>
                  </div>
                  <div className="projectDescription">
                  This social media app serves as a hub for both developers seeking opportunities and clients looking to hire skilled professionals. 
                  It allows users to follow profiles and add reviews, projects and comments on profiles of their interest.
                  <br/>
                  
                  Built with React, Python and PostgreSQL. 
                  </div>
                </div>
                <div className="projectPicture" id="projectFourPicture"></div>
              </div>
            </a>

            <a
              href="https://dev--jovial-macaron-61c636.netlify.app/"
              target="_blank"
            >
              <button className="projectButton">HIRE ME →</button>
            </a>
          </div>




{/* This is the --- WEATHER APP --- project 
  _____________________________________________________________ */}
          <div className="projectSticky">
            <a href="https://weatheriparrea.netlify.app/" target="_blank">
              <div className="project">
                
                <div className="projectInfo">
                  <div className="projectTitle">
                    <h1 className="projectTitleLineOne">Weather</h1>
                    <h1 className="projectTitleLineTwo">App</h1>
                  </div>
                  <div className="projectDescription">
                    Stay informed about the weather wherever you go with my
                    React Weather App. Utilizing React hooks and API calls, it
                    provides real-time updates with precision. Its responsive
                    design ensures seamless access on all devices, helping you
                    plan your day effortlessly.
                  </div>
                </div>
                <div className="projectPicture" id="projectTwoPicture"></div>
              </div>
            </a>
            <a href="https://ciparrea.github.io/WeatherApp/" target="_blank">
              <button className="projectButton">CHECK IT OUT →</button>
            </a>
          </div>

{/* This is the --- PARALLAX --- project 
  _____________________________________________________________ */}
          <div className="projectSticky">
            <a
              href="https://cesariparreaparallaxeffect.netlify.app/"
              target="_blank"
            >
              <div className="project">
                <div className="projectInfo">
                  <div className="projectTitle">
                    <h1 className="projectTitleLineOne">Parallax</h1>
                    <h1 className="projectTitleLineTwo">Effect</h1>
                  </div>
                  <div className="projectDescription">
                    Explore a mesmerizing code of my React-powered 3D website's
                    intro page. Playing with layers of content that dynamically
                    shift as you hover, offering an engaging and immersive
                    experience enhanced by visual effects, including layering
                    and cursor positioning on the screen.
                  </div>
                </div>
                <div className="projectPicture" id="projectThreePicture"></div>
              </div>
            </a>

            <a
              href="https://cesariparreaparallaxeffect.netlify.app/"
              target="_blank"
            >
              <button className="projectButton">EXPLORE →</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sticky;
